<template>
  <div>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <v-row>
          <v-col cols="12">
            <h1>Chat to a vet</h1>
            <h3 class="mt-3">The typical wait time for a vet is 5 minutes</h3>
          </v-col>
          <v-spacer/>
          <v-col class="py-0" cols="12" sm="6">
            <select-input
                v-model="selectedPetUuid"
                :items="filteredPets"
                rules="required"
                label="Select pet*"
                outlined
            />
          </v-col>
          <v-col class="py-0" cols="12" sm="6">
            <select-input
                v-model="consult.consult_type"
                :items="types"
                rules="required"
                label="Consultation type*"
                outlined
            />
          </v-col>
        </v-row>
        <hr class="my-5">
        <v-spacer></v-spacer>
        <v-row>
          <v-col cols="12">
            <h3 class="mt-3">Vet chat consult details</h3>
          </v-col>
          <v-col cols="12" sm="6">
            <text-input
                v-model="consult.firstName"
                rules="required"
                label="First Name*"
                name="First Name"
            ></text-input>
          </v-col>
          <v-col cols="12" sm="6">
            <text-input
                v-model="consult.lastName"
                rules="required"
                label="Last Name*"
                name="Last Name"
            ></text-input>
          </v-col>
          <v-col class="py-0" cols="12" sm="6">
            <text-input
                rules="required|email"
                label="Email"
                name="Email"
                vid="email"
                v-model="consult.email"
            />
          </v-col>
          <v-col cols="12" sm="6" class="py-0">
            <phone-number-input-component
                rules="required"
                label="Mobile phone"
                name="Mobile phone"
                v-model="consult.mobile"
                id="mobile-phone"
                @errorValue="getMobileErrorValue"
                vid="phone_mobile"
                outlined
            />
          </v-col>
          <v-col class="py-0" cols="12" sm="6">
            <text-input
                v-model="consult.pet_name"
                rules="required"
                label="Pet Name*"
                name="Pet Name"
            ></text-input>
          </v-col>
          <v-col class="py-0" cols="12" sm="3">
            <select-input
                v-model="consult.pet_species"
                :items="petTypes"
                rules="required"
                label="Pet Type*"
                name="Pet Type"
                @input="getBreeds"
            />
          </v-col>
          <v-col class="py-0" cols="12" sm="3">
            <select-input
                v-model="consult.pet_sex"
                :items="petSexes"
                rules="required"
                label="Pet Sex*"
                name="Pet Sex"
            />
          </v-col>
          <v-col class="py-0" cols="12" sm="6">
            <select-input
                v-model="consult.pet_breed"
                rules="required"
                :items="breeds"
                label="Pet Breed*"
                name="Pet Breed"
            />
          </v-col>
          <v-col class="pt-0 pb-5 py-sm-0" cols="12" sm="6">
            <date-picker
                v-model="consult.pet_dob"
                outlined
                label="Date of Birth*"
                name="Date of Birth"
                :max="today"
                hide-details
                rules="required"
            >
            </date-picker>
          </v-col>
          <v-col class="pt-sm-0" cols="12" sm="6">
            <ValidationProvider tag="div" rules="required" name="Notes" v-slot="{ errors }">
              <v-textarea
                  v-model="consult.query"
                  :error-messages="errors"
                  label="Provide us with some details on what you are wanting to cover in your consult*"
                  auto-grow
                  outlined
                  :maxlength="500"
                  rows="8"
                  counter
              />
            </ValidationProvider>
            <div class="d-flex align-content-center">
              <v-checkbox class="mt-0" v-model="termsAndConditions">
                <template v-slot:label>
                  <div>
                    I agree to the
                    <a
                        target="_blank"
                        href="https://vetchat.vchat.com.au/terms-and-conditions"
                        @click.stop
                    >
                      terms and conditions
                    </a>
                  </div>
                </template>
              </v-checkbox>
            </div>
          </v-col>
        </v-row>
        <v-row class="mb-0 mt-0">
          <v-col cols="12" sm="6">
            <v-btn color="success"
                   block
                   type="submit"
                   :loading="loading"
                   :disabled="!termsAndConditions || error || !hasActiveSubscription"
            >
              Start a consult
            </v-btn>
            <v-alert
                v-if="!hasActiveSubscription"
                dense
                text
                type="warning"
                class="mb-0 mt-2">
              You can not start a consult without an active subscription
            </v-alert>
          </v-col>
        </v-row>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import SelectInput from "@/components/SelectInputComponent.vue";
import TextInput from "@/components/TextInputComponent.vue";
import DatePicker from "@/components/DatePickerComponent.vue";
import moment from "moment/moment";
import {ValidationObserver, ValidationProvider} from 'vee-validate';
import PhoneNumberInputComponent from "@/components/PhoneNumberInputComponent.vue";
import store from "@/store";
import PetsRepository from '../api/PetsRepository'

export default {
  name: 'SpeakToVet',
  title: 'Speak to a vet',
  components: {
    PhoneNumberInputComponent,
    ValidationProvider,
    DatePicker,
    TextInput,
    SelectInput,
    ValidationObserver,
  },
  data: () => ({
    consult: {},
    selectedPetUuid: null,
    termsAndConditions: false,
    error: false,
    loading: false,
    years: 0,
    months: 0,
    types: [
      {text: 'Text', value: 'text'},
      {text: 'Video', value: 'video'},
    ],
    petTypes: [
      {text: 'Dog', value: 'dog'},
      {text: 'Cat', value: 'cat'},
    ],
    petSexes: [
      {text: 'Male', value: 'M'},
      {text: 'Female', value: 'F'},
    ],
    breeds: [],
  }),
  async mounted() {
    await this.loadPets()
    this.consult = {
      firstName: this.user.customer.first_name,
      lastName: this.user.customer.last_name,
      mobile: this.user.customer.phone_mobile,
      email: this.user.customer.email,
      pet_dob: null
    }
  },
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters({
      user: 'auth/user',
      hasActiveSubscription: 'auth/hasActiveSubscription',
      pets: 'pet/pets'
    }),
    filteredPets() {
      return this.pets.map((p) => {
        return {
          text: p.name,
          value: p.uuid
        }
      })
    },
    today () {
      return moment().format('Y-MM-DD')
    },
  },
  methods: {
    ...mapActions({
      loadPets: 'pet/index',
      startVetConsult: 'customer/startVetConsult',
    }),
    async onSubmit () {
      this.loading = true;
      let vetConsult = Object.assign({}, this.consult);
      await this.startVetConsult({
        uuid: this.user.customer.uuid,
        payload: vetConsult
      }).then(async (response) => {
        try {
          window.open(this.user.vet_chat_api + 'consult/' + response.consultation.id + '/' + response.token + '/' + this.getConsultationType(response.consultation.type))
        } catch (e) {
          store.dispatch('toast/trigger', {text: "Consult can not be initiated", color: "red"}, {root: true});
        }
      })
      this.loading = false;
    },
    getConsultationType(value) {
      switch (value) {
        case 1:
          return 'text'
        case 2:
          return 'video'
        default:
          return null
      }
    },
    getMobileErrorValue(error) {
      this.error = error
    },
    calculateAgeBasedOnDob(dob) {
      const today = new Date();
      const birthDate = new Date(dob);
      let age = today.getFullYear() - birthDate.getFullYear();
      const monthDifference = today.getMonth() - birthDate.getMonth();

      if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }

      return age;
    },
    async getBreeds() {
      this.breeds = await PetsRepository.getBreeds(this.consult.pet_species);
    }
  },
  watch: {
    selectedPetUuid(uuid) {
      const selectedPet = this.pets.find(p => p.uuid === uuid)

      this.consult = {
        ...this.consult,
        pet_name: selectedPet.name,
        pet_species: selectedPet.petType,
        pet_breed: selectedPet.breed,
        pet_age: this.calculateAgeBasedOnDob(selectedPet.dob),
        pet_sex: selectedPet.sex,
        pet_dob: selectedPet.dob,
      }
    }
  }
}
</script>

